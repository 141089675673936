const config = {
  dns: "buybye.reckon.ai",
  //dns_app: "reckon-adyen.buybye.app",
  dns_app: "lekkerland-frischwerk-terminal.buybye.app",
  useLocale: true,
  buybyeURL: "https://buybye-prod.reckon.ai/app",
  authURL: "https://auth-qr.reckon.ai/app",
  changeLocale: true,
  privacyPolicyUrl:
    "https://frischwerk-terms.reckon.ai/privacy-policy",
  termAndConditionsUrl: 
  "https://frischwerk-terms.reckon.ai/terms-and-conditions"
};

export default config;
